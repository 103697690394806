<template lang="pug">
modal(size='sm' @close='$emit("close")')
  modal-header(class='mb-0') {{ $t('Add as alternative to:') }}
  control-input(v-if='project && project.items.length' v-model='query' rounded :placeholder='$t("Produkte suchen...")' grey icon='search' class='-mx-1 mb-2')
  empty(v-if='results && results.length === 0')

  div(class='bleed')
    div(class='max-h-[calc(100vh-200px)] overflow-auto')
      div(class='mx-2 flex flex-col')
        btn-select(v-for='{ item, matches } in results' :key='item.productId' :disabled='item.productId === productId' :hoverText='$t("choose")' @click='submit(item.productId)')
          template(#image)
            project-product-image(:url='item.image' size='30' class='w-[40px]')
          div(class='text-sm text-text-light') {{ item.brand }}
          div(v-html='matches ? highlight(item.title, matches[0].indices) : item.title')
</template>

<script setup lang="ts">
import { useProjectsStore } from '../store'
import BtnSelect from '@/components/BtnSelect.vue'
import { highlight, useFuzzySearch } from '@/composables/'
import { computed, ref } from 'vue'

const props = defineProps<{ projectId: string; productId: string }>()
const query = ref('')
const emit = defineEmits(['close'])
const store = useProjectsStore()
const project = computed(() => store.project(props.projectId) || {})
const { results } = useFuzzySearch(
  query,
  computed(() => project.value.items),
  ['title'],
)
const submit = (targetId: string) =>
  store.addAlternatives(props.projectId, [props.productId], targetId).then(() => emit('close'))
</script>
